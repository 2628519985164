/* eslint-disable camelcase */
import { FC, memo } from 'react';
import usePrice from '@framework/use-price';

import * as styles from './styles';

type Props = {
  priceBase: number;
  priceDiscount?: number;
  isOnSale: boolean;
  currencyCode: string;
  slug: string;
};

// TODO: Remove 'suppressHydrationWarning' from price items
// suppressHydrationWarning is a temporary solution for the server/client mismatch

const ProductPrice: FC<Props> = ({
  priceBase,
  priceDiscount,
  currencyCode,
  isOnSale,
  slug,
}) => {
  const { price: discoPrice } = usePrice({
    amount: priceDiscount,
    currencyCode,
  });

  const { price } = usePrice({
    amount: priceBase,
    currencyCode,
  });

  return price ? (
    <div>
      <p
        suppressHydrationWarning
        className={styles.isOnSale(isOnSale)}
        id={`${slug}-price`}
        data-test-id="prebuild-card-price"
      >
        {price}
      </p>
      {isOnSale && (
        <p suppressHydrationWarning className={styles.discountedPricedText}>
          {discoPrice}
        </p>
      )}
    </div>
  ) : null;
};

export default memo(ProductPrice);
