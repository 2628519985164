/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import cn from 'clsx';
import HydrateMdx from '@components/HydrateMdx';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import extraStyles from '../extraStyles.module.css';
import * as styles from './styles';

type Props = {
  label?: string;
  markdown: MDXRemoteSerializeResult;
  baseContent: string;
  showMore?: boolean;
};

const DataCell: FC<Props> = ({ label, markdown, baseContent, showMore }) =>
  markdown || baseContent ? (
    <div className={styles.dataCell(showMore)} data-test-id="small-data-cell">
      <span className={styles.dataLabel}>{label}</span>
      <div className={cn(styles.data, extraStyles.markdown)}>
        {markdown ? <HydrateMdx mdx={markdown} /> : baseContent}
      </div>
    </div>
  ) : null;

export default memo(DataCell);
