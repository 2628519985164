/* eslint-disable max-len */
import { FC, memo } from 'react';
import type { Page, ReviewsListItem } from '@framework/api/types';
import { useTranslation as t } from '@utils/hooks';
import ContentWrapper from '@components/ContentWrapper';
import Pagination from '@components/Pagination';
import PageComponent from '@components/Page';
import SectionWrapper from '@components/SectionWrapper';
import ReviewsItem from './ReviewsItem';
import * as styles from './styles';

type Props = {
  page?: Page;
  reviews: ReviewsListItem[];
  totalPages?: number;
  currentPage?: number;
  routePrefix?: string;
  count?: {
    count: number;
  };
  isCustomPC?: boolean;
};

const ReviewsList: FC<Props> = ({
  reviews,
  page,
  totalPages,
  currentPage,
  routePrefix,
  count,
  isCustomPC,
}) => {
  const numberOfStars = [1, 2, 3, 4, 5];

  return (
    <SectionWrapper>
      {page && <PageComponent page={page} titleStyles={styles.title} />}
      <ContentWrapper>
        <h3 className="h3">{t('reviews_label')}</h3>
        {count && (
          <div className={styles.topWrapper}>
            <div className={styles.starsWrapper}>
              {numberOfStars.map(star => (
                <svg
                  key={star}
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles.star}
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
              ))}
            </div>
            <p className={styles.reviewText}>{count.count} reviews</p>
          </div>
        )}
        <div className={styles.reviewsWrapper}>
          <ul className={styles.reviewsList}>
            {Array.isArray(reviews) &&
              reviews.map(review => (
                <ReviewsItem review={review} key={review.username} />
              ))}
          </ul>
        </div>

        {isCustomPC && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            routePrefix={routePrefix}
          />
        )}
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default memo(ReviewsList);
