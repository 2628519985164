import { SubscriptionProductCollection } from 'fragile/types';
import { FC, memo } from 'react';
import ContentWrapper from '@components/ContentWrapper';
import SectionHeading from '@components/SectionHeading';
import { useRouter } from 'next/router';
import SectionWrapper from '@components/SectionWrapper';
import LineupCard from './LineupCard';
import * as styles from './styles';

type Props = {
  heading: string;
  subscriptionProductCollections: SubscriptionProductCollection[];
};

const CollectionLineup: FC<Props> = ({
  heading,
  subscriptionProductCollections,
}) => {
  const { locale } = useRouter();

  const sortedProducts = subscriptionProductCollections.sort(
    (a, b) => a.monthlyPrice - b.monthlyPrice
  );

  return (
    <ContentWrapper>
      <SectionWrapper>
        <div className={styles.headingWrapper}>
          <SectionHeading textPosition="left" heading={heading} lang={locale} />
        </div>
        <div className={styles.gridContainer}>
          {sortedProducts.map(collection => (
            <LineupCard
              key={collection.fromCms.slug}
              name={collection.fromCms.name}
              slug={collection.fromCms.slug}
              productPrice={{
                isRecurring: true,
                recurringInterval: 'MONTH',
                amount: collection.monthlyPrice,
              }}
              upgradeOptions={collection.products[0].upgrades[0].options.sort(
                (a, b) => a.displayOrder - b.displayOrder
              )}
              subscriptionProducts={collection.products}
              specs={collection.fromCms.specs}
              fragileProductCollection={collection}
              isSubscription
            />
          ))}
        </div>
      </SectionWrapper>
    </ContentWrapper>
  );
};

export default memo(CollectionLineup);
