import {
  memo,
  FC,
  ReactNode,
  CSSProperties,
  Fragment,
  useCallback,
} from 'react';
import cn from 'clsx';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import { useMedia } from '@utils/hooks';
import Button from '@components/Button';
import ButtonLink from '@components/ButtonLink';
import { ROUTE_SUPPORT } from '@constants';
import type { DatoProductImage } from '@framework/api/types';
import * as styles from './styles';
import extraStyles from './extraStyles.module.css';

type Props = {
  heading?: string;
  subheading?: string;
  buttonLabel?: string;
  openChatbox?: boolean;
  image?: DatoProductImage;
  smallBlock?: boolean;
  background?: 'transparent' | 'light' | 'dark';
};

type SmallWrapperProps = {
  children: ReactNode;
  className: string;
  style: CSSProperties;
};

const SmallWrapper: FC<SmallWrapperProps> = ({
  children,
  className,
  style,
}) => (
  <div className={className} style={style}>
    {children}
  </div>
);

const HelpBlock: FC<Props> = ({
  heading,
  subheading,
  image,
  buttonLabel,
  background,
  openChatbox = false,
  smallBlock = false,
}) => {
  const openChat = openChatbox && typeof window !== 'undefined' && window.zE;
  const isInverted = background === 'dark';
  const SmallWrap = smallBlock ? SmallWrapper : ContentWrapper;
  const SmallSection = smallBlock ? Fragment : SectionWrapper;
  const isSm = useMedia('sm');
  const isMd = useMedia('md');

  const isLg = !isSm && !isMd;

  const backgroundGradientColors = {
    light: extraStyles.backgroundLight,
    transparent: 'rgba(255,255,255,0)',
    dark: extraStyles.backgroundDark,
  };

  const backgroundColors = {
    light: '#ebebf0',
    dark: '#1a1c1e',
  };

  const xPostition = isLg && !smallBlock ? '80%' : '100%';
  const hasImageStyles = {
    background: `url(${
      image?.responsiveImage?.src || '/defaultImages/support-banner.svg'
    }) ${backgroundColors[background]}`,
    backgroundPositionX: xPostition,
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    minHeight: '137px',
  };

  const onClick = useCallback(() => {
    if (openChat && process.env.NEXT_PUBLIC_FEATURE_FLOWBUILDER === 'true') {
      window.zE('messenger', 'open');
    }

    if (openChat && process.env.NEXT_PUBLIC_FEATURE_FLOWBUILDER !== 'true') {
      window.zE('webWidget', 'open');
    }
  }, [openChat]);

  return (
    <SmallSection>
      <SmallWrap
        className={cn(
          styles.wrapper(smallBlock),
          styles.backgroundStyles[background],
          backgroundGradientColors[background]
        )}
        style={hasImageStyles}
      >
        <div className={styles.textWrapper(smallBlock)}>
          <h3 className={styles.heading(smallBlock, isInverted)}>{heading}</h3>
          <p className={styles.subheading(smallBlock, isInverted)}>
            {subheading}
          </p>
          {openChat ? (
            <Button
              buttonStyle="primary"
              dark={isInverted}
              type="button"
              onClick={onClick}
              className={styles.extendedButton}
              customMargin
            >
              {buttonLabel}
            </Button>
          ) : (
            <ButtonLink
              buttonStyle="primary"
              href={`/${ROUTE_SUPPORT}`}
              dark={isInverted}
              className={styles.extendedButton}
              customMargin
              internalLink
            >
              {buttonLabel}
            </ButtonLink>
          )}
        </div>
      </SmallWrap>
    </SmallSection>
  );
};

export default memo(HelpBlock);
