/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useRef, useEffect, useState, useMemo } from 'react';
import cn from 'clsx';
import type {
  PrebuiltCardsProps,
  PrebuildComparisonField,
  ComparisonProduct,
  DatoProduct,
} from '@framework/api/types';
import { useTranslation as t } from '@utils/hooks';
import FpsSection from '@components/FpsSection';
import FeaturesCell from '../FeaturesCell';
import TopSection from './TopSection';
import DataCell from './DataCell';
import * as styles from './styles';

type Props = {
  prebuiltCards: PrebuiltCardsProps;
  comparisonProducts: DatoProduct[] | ComparisonProduct[];
  currencyCode: string;
  showMore: boolean;
  enabledCardFields: PrebuildComparisonField[];
  chipsetType?: string;
  region?: string;
};

const PrebuiltComparisonTableLg: FC<Props> = ({
  prebuiltCards,
  showMore,
  currencyCode,
  comparisonProducts,
  enabledCardFields,
  chipsetType,
  region,
}) => {
  const ref = useRef<HTMLElement[]>([]);
  const [minHeight, setMinHeight] = useState(null);
  useEffect(() => {
    if (ref?.current) {
      const heightOfChild =
        Array.isArray(ref?.current) &&
        ref?.current.map(item => {
          const children = item?.children && [...item.children];

          return children?.map(childEl => {
            const { height } = childEl?.getBoundingClientRect() ?? {
              height: 0,
            };
            return height;
          });
        });
      const deduped = heightOfChild
        .filter(item => item && item)
        .reduce((elem1, elem2) => elem1.concat(elem2));

      setMinHeight(Math.max(...deduped));
    }
  }, [ref, minHeight]);

  const productLength = Array.isArray(prebuiltCards)
    ? prebuiltCards?.length
    : 0;
  const composedColumnClassName = useMemo(
    () =>
      cn(
        styles.column,
        styles.colSpan[(productLength <= 4 && productLength) || 3]
      ),
    [productLength]
  );

  const CASE = t('label_case');
  const SOFTWARE = t('label_software');
  const RAM = t('label_ram');
  const CPU = t('label_cpu');
  const GPU = t('label_gpu');
  const MOTHERBOARD = t('label_motherboard');
  const STORAGE = t('label_storage');
  const COOLING = t('label_cooling');
  const PSU = t('label_psu');
  const WARRANTY = t('label_warranty');
  const ACCESSORIES = t('label_accessories');
  const FEATURE = t('label_feature');

  return prebuiltCards &&
    Array.isArray(prebuiltCards) &&
    prebuiltCards?.length > 0 ? (
    <>
      <TopSection
        comparisonProducts={comparisonProducts}
        currencyCode={currencyCode}
        region={region}
        productLength={productLength}
      />
      {prebuiltCards?.map((card, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${card.gpu}-${i}`} className={composedColumnClassName}>
          <div className={styles.fpsCellWrapper}>
            <FpsSection
              fps={card.productFps}
              chipsetType={chipsetType}
              isDefaultButton
            />
          </div>
          <div
            className={styles.column}
            ref={element => ref?.current.push(element)}
          >
            {enabledCardFields.some(f => f.field === 'cpu') && (
              <DataCell
                label={CPU}
                markdown={card.cpuMd}
                baseContent={card.cpu}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore
              />
            )}
            {enabledCardFields.some(f => f.field === 'gpu') && (
              <DataCell
                label={GPU}
                markdown={card.gpuMd}
                baseContent={card.gpu}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore
              />
            )}
            {enabledCardFields.some(f => f.field === 'ram') && (
              <DataCell
                label={RAM}
                markdown={card.ramMd}
                baseContent={card.ram}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore
              />
            )}
            {enabledCardFields.some(f => f.field === 'storage') && (
              <DataCell
                label={STORAGE}
                markdown={card.storageMd}
                baseContent={card.storage}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore
              />
            )}
            {enabledCardFields.some(f => f.field === 'case') && (
              <DataCell
                label={CASE}
                markdown={card.caseMd}
                baseContent={card.case}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore={showMore}
              />
            )}
            {enabledCardFields.some(f => f.field === 'motherboard') && (
              <DataCell
                label={MOTHERBOARD}
                markdown={card.motherboardMd}
                baseContent={card.motherboard}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore={showMore}
              />
            )}
            {enabledCardFields.some(f => f.field === 'cpuCooler') && (
              <DataCell
                label={COOLING}
                markdown={card.cpuCoolerMd}
                baseContent={card.cpuCooler}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore={showMore}
              />
            )}
            {enabledCardFields.some(f => f.field === 'power') && (
              <DataCell
                label={PSU}
                markdown={card.powerMd}
                baseContent={card.power}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore={showMore}
              />
            )}
            {enabledCardFields.some(f => f.field === 'accessories') && (
              <DataCell
                label={ACCESSORIES}
                markdown={card.accessoriesMd}
                baseContent={card.accessories}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore={showMore}
              />
            )}
            {enabledCardFields.some(f => f.field === 'software') && (
              <DataCell
                label={SOFTWARE}
                markdown={card.softwareMd}
                baseContent={card.software}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore={showMore}
              />
            )}
            {enabledCardFields.some(f => f.field === 'warranty') && (
              <DataCell
                label={WARRANTY}
                markdown={card.warrantyMd}
                baseContent={card.warranty}
                isFirstCol={i === 0}
                minHeight={minHeight}
                showMore={showMore}
              />
            )}
            <FeaturesCell
              label={FEATURE}
              isFirstCol={i === 0}
              minHeight={minHeight}
              hasBluetooth={card.bluetooth}
              hasRgb={card.rgb}
              hasWifi={card.wifi}
              hasUsbc={card.usbc}
              isStreamReady={card.streamReady}
              isVrReady={card.vrReady}
              productLength={productLength}
            />
          </div>
        </div>
      ))}
    </>
  ) : null;
};

export default memo(PrebuiltComparisonTableLg);
