import { classnames } from '@tailwindcss-classnames';

export const wrapper = classnames('space-y-2', 'text-center', 'mb-6');
export const heading = classnames(
  'h3',
  'px-8',
  'md:max-w-screen-sm',
  'lg:max-w-screen-md',
  'md:px-12',
  'mx-auto'
);
export const subheading = classnames(
  'text-nzxt-light-grey-600',
  'w-11/12',
  'md:max-w-screen-sm',
  'lg:max-w-screen-lg',
  'mx-auto'
);
