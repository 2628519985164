import { classnames } from '@tailwindcss-classnames';

export const image = classnames(
  'z-0',
  'max-w-lg',
  'w-52',
  'lg:w-52',
  'mx-auto'
);
export const imageWrapper = classnames('relative', 'w-full', 'p-4', 'mb-1');
export const gridContainer = classnames(
  'grid',
  'grid-cols-1',
  'md:grid-cols-3',
  'gap-4'
);
export const growWrapper = classnames('flex-grow', 'mt-1');
export const container = classnames('mb-4');
export const contentContainer = classnames(
  'flex',
  'flex-col',
  'gap-4',
  'w-full',
  'px-4'
);
export const contentContainerNoBleed = classnames('flex', 'flex-col', 'w-full');
export const cardContainer = classnames(
  'bg-nzxt-light-grey-100',
  'flex',
  'flex-col',
  'items-center',
  'p-1',
  'rounded-sm'
);
export const cardWrapper = classnames('flex', 'flex-col', 'h-full', 'text-sm');
export const priceContainer = classnames('flex', 'flex-wrap', 'w-full');
export const smallText = classnames('text-xs', 'md:text-sm');
export const largeText = classnames('text-base', 'font-semibold');
export const subText = classnames('text-sm', 'opacity-60');
export const titleText = classnames(
  'text-xl',
  'md:text-lg',
  'font-bold',
  'mb-1'
);
export const priceTitle = classnames('text-lg');
export const specsList = classnames('flex', 'flex-col', 'gap-2');
export const specsItem = classnames();
export const specsItemKey = classnames('font-semibold');
export const headingWrapper = classnames('col-span-12');
