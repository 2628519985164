import { classnames } from '@tailwindcss-classnames';

export const link = classnames('my-4');

export const placeholder = classnames('bg-nzxt-dark-grey-100', 'opacity-25');

export const button = classnames(
  'hidden',
  'lg:flex',
  'bg-white',
  'border',
  'rounded-full',
  'items-center',
  'justify-center',
  'p-2',
  'transition-opacity-scale',
  'duration-300'
);

export const scrollerWrapper = classnames(
  'flex',
  'gap-4',
  'snap-mandatory',
  'snap-x',
  'scrollbar',
  'overflow-auto',
  'max-w-[1200px]'
);

export const embedItemWrapper = classnames(
  'w-[325px]',
  'min-h-[757px]',
  'snap-center',
  'shrink-0'
);
