/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-shadow */
import type { SWRResponse } from 'swr';
import type {
  HookInput,
  HookFetcher,
  HookFetcherOptions,
} from '../../utils/types';
import useData, { SwrOptions } from '../../utils/use-data';

export type OembedResponse<Result> = SWRResponse<Result, Error>;

export type OembedInput = {
  url: string | undefined;
};

export default function useOembed<Result>(
  options: HookFetcherOptions,
  input: HookInput,
  fetcherFn: HookFetcher<Result, OembedInput>,
  swrOptions?: SwrOptions<Result, OembedInput>,
  url?: string
) {
  const fetcher: typeof fetcherFn = (options, input, fetch) => {
    input.url = url;

    return fetcherFn(options, input, fetch);
  };

  const response = useData(options, input, fetcher, swrOptions);

  return Object.assign(response) as OembedResponse<Result>;
}
