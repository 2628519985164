/* eslint-disable react/jsx-no-useless-fragment */
import { FC, memo, useEffect, useState, useMemo } from 'react';
import { ComparisonProduct, DatoProduct } from '@framework/api/types';
import cn from 'clsx';
import useUiStore, {
  getDisplayPreviewBanner,
  getDisplayMarketingBanner,
} from '@stores/use-ui-store';
import useToastStore, { getDisplayToast } from '@stores/use-toast-store';

import { useMedia } from '@utils/hooks';

import Price from '../Price';
import * as styles from './styles';

type Props = {
  comparisonProducts: DatoProduct[] | ComparisonProduct[];
  currencyCode: string;
  region?: string;
  productLength?: number;
};

const PrebuiltComparisonTableSm: FC<Props> = ({
  currencyCode,
  comparisonProducts,
  region,
  productLength,
}) => {
  const [navPosition, setNavPosition] = useState('0');
  const isMd = useMedia('md');
  const isSm = useMedia('sm');
  const displayPreviewBanner = useUiStore(getDisplayPreviewBanner);
  const displayMarketingBanner = useUiStore(getDisplayMarketingBanner);
  const displayToast = useToastStore(getDisplayToast);

  const composedHeaderClassName = useMemo(
    () =>
      cn(
        styles.productHeadingWrapper,
        styles.colSpan[(productLength <= 4 && productLength) || 3]
      ),
    [productLength]
  );

  useEffect(() => {
    let sm = 64;
    let md = 64;
    let lg = 52;

    if (displayPreviewBanner) {
      sm += 52;
      md += 52;
      lg += 52;
    }

    if (displayToast) {
      sm += 52;
      md += 72;
    }

    if (displayMarketingBanner) {
      sm += 45;
      md += 45;
      lg += 45;
    }

    if (!isMd && !isSm) {
      setNavPosition(`calc(${lg}px)`);
    }

    if (isMd && !isSm) {
      setNavPosition(`calc(${md}px)`);
    }

    if (isSm) {
      setNavPosition(`calc(${sm}px)`);
    }
  }, [displayPreviewBanner, displayToast, displayMarketingBanner, isMd, isSm]);

  return comparisonProducts &&
    Array.isArray(comparisonProducts) &&
    comparisonProducts?.length > 0 ? (
    <>
      {comparisonProducts?.map(item => {
        const isRegionallyAvailable = Array.isArray(item?.availableRegions)
          ? item?.availableRegions.some(r => r.region === region)
          : false;

        return item ? (
          <div
            key={item?.name}
            className={composedHeaderClassName}
            style={{ top: navPosition, zIndex: 1, marginLeft: '-1px' }}
          >
            <h3 className={styles.productHeading}>{item.name}</h3>
            {isRegionallyAvailable && (
              <Price
                slug={item?.slug}
                priceBase={item?.variants[0].price}
                priceDiscount={item?.variants[0].discounted_price}
                isOnSale={item?.variants[0].isOnSale}
                currencyCode={currencyCode}
              />
            )}
          </div>
        ) : null;
      })}
    </>
  ) : null;
};

export default memo(PrebuiltComparisonTableSm);
