/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
/* eslint-disable react/no-danger */
import {
  FC,
  useEffect,
  useState,
  memo,
  CSSProperties,
  ReactElement,
  ComponentType,
} from 'react';
import type { ReactNode } from 'react';
import cn from 'clsx';
import type {
  PhotoEmbedResponse,
  LinkEmbedResponse,
} from '@framework/api/types';
import useOembed from '@framework/oembed/use-oembed';
import useScript from './use-script';
import * as styles from './styles';
import css from './Embed.module.css';

type Props = {
  url: string;
  style?: CSSProperties;
  FallbackElement?: ReactElement;
  LoadingFallbackElement?: ReactElement;
  ImgComponent?: ComponentType<{ response?: PhotoEmbedResponse }>;
  LinkComponent?: ComponentType<{ response?: LinkEmbedResponse }>;
  isWide?: boolean;
  centered?: boolean;
};

const EmbedItem: FC<Props> = ({
  url,
  style,
  ImgComponent,
  LinkComponent,
  FallbackElement,
  LoadingFallbackElement,
  isWide = false,
  centered = false,
}) => {
  const [html, setHtml] = useState('');

  const { data, error } = useOembed(url);

  useScript(html, { defer: '' });

  useEffect(() => {
    if (data && data.html) {
      setHtml(data.html);
    }
  }, [data]);

  const Link = (
    <a
      className={styles.link}
      href={url}
      target="_blank"
      rel="nofollow noreferrer noopener"
    >
      {url}
    </a>
  );

  const Placeholder =
    LoadingFallbackElement || (
      <div
        className={cn(styles.placeholder, css.embed_placeholder, {
          [css.embed]: !isWide,
          [css.videoEmbedWide]: isWide,
        })}
      />
    ) ||
    Link;

  let CustomNode: ReactNode;

  if (data && !data.html) {
    if (data.type === 'photo') {
      if (ImgComponent) {
        CustomNode = <ImgComponent response={data} />;
      } else CustomNode = <img alt="" src={data.url} />;
    } else if (data.type === 'link') {
      if (LinkComponent) {
        CustomNode = <LinkComponent response={data} />;
      } else
        CustomNode = (
          <a href={url} target="_blank" rel="nofollow noreferrer noopener">
            {url}
          </a>
        );
    }
  }

  if (error) {
    return FallbackElement || Link;
  }

  if (data) {
    return (
      <div
        style={style}
        className={cn(css.embed_column, 'mb-4', {
          [css.embed]: !isWide,
          [css.videoEmbedWide]: isWide,
        })}
      >
        {CustomNode}
        {html ? (
          <div
            className={cn(css.embed_column, { [css.embedCenter]: centered })}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : (
          <>{Placeholder}</>
        )}
      </div>
    );
  }

  return Placeholder;
};

export default memo(EmbedItem);
