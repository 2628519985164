/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { HookFetcher } from '../utils/types';
import type { SwrOptions } from '../utils/use-data';
import useOembedGetPayload, {
  OembedInput,
} from '../commerce/oembed/use-oembed';
import type { EmbedResponse } from '../api/types';

export const fetcher: HookFetcher<EmbedResponse | null, OembedInput> = (
  options,
  { url },
  fetch
) => {
  const defaultOpts = {
    url: `/api/oembed?url=${url}`,
    method: 'GET',
  };

  return url ? fetch({ ...defaultOpts, ...options }) : null;
};

export function extendHook(
  customFetcher: typeof fetcher,
  swrOptions?: SwrOptions<EmbedResponse | null, OembedInput>
) {
  const useOembed = (url: string) => {
    const response = useOembedGetPayload(
      {
        url: `/api/oembed?url=${url}`,
        method: 'GET',
      },
      [],
      customFetcher,
      {
        revalidateOnFocus: false,
        ...swrOptions,
      },
      url
    );

    return response;
  };

  useOembed.extend = extendHook;

  return useOembed;
}

export default extendHook(fetcher);
