import { classnames } from '@tailwindcss-classnames';

export const heading = classnames('h2', 'mb-4');
export const gridWrapper = classnames('grid', 'grid-cols-12');

export const buttonStyles = classnames('ml-auto', 'mt-4');
export const contentBg = classnames(
  'bg-white',
  'md:p-8',
  'rounded-lg',
  'relative'
);
const priceDiscounted = classnames('line-through', 'text-nzxt-light-grey-500');
export const discountedPricedText = classnames('text-nzxt-light-grey-800');
export const isOnSale = (salePrice: boolean): string =>
  classnames('text-sm', 'md:text-base', { [priceDiscounted]: salePrice });
