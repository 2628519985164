import { classnames } from '@tailwindcss-classnames';

const smallCard = classnames('p-0');
const defaultCard = classnames('pb-3', 'px-3');
export const cardsGrid = (isSmall: boolean): string =>
  classnames('grid', 'gap-4', 'grid-cols-6', {
    [smallCard]: isSmall,
    [defaultCard]: !isSmall,
  });

const defaultFpsContainer = classnames(
  'flex-col',
  'bg-white',
  'border',
  'border-nzxt-light-grey-100',
  'col-span-3',
  'items-center',
  'justify-center',
  'p-2'
);
const smallFpsContainer = classnames(
  'flex-col',
  'bg-white',
  'border',
  'border-nzxt-light-grey-100',
  'col-span-3',
  'items-center',
  'justify-center',
  'py-2',
  'md:p-2'
);
export const detailsWrapper = (isSmall: boolean): string =>
  classnames('flex', 'space-x-2', 'rounded-lg', {
    [defaultFpsContainer]: !isSmall,
    [smallFpsContainer]: isSmall,
  });

export const full = classnames(
  'text-center',
  'flex',
  'justify-center',
  'items-center'
);

export const textBase = classnames('text-gray-500', 'font-semibold', 'text-xs');
export const textFps = classnames(textBase, 'font-bold', 'text-right');
export const flexCenter = classnames('flex', 'items-center');
export const innerWrapper = classnames('w-full', 'flex', 'flex-col');

export const fpsBarWrapper = classnames(
  'bg-nzxt-light-grey-300',
  'overflow-hidden',
  'w-full',
  'rounded-lg'
);

export const fpsBar = classnames(
  'bg-gradient-to-r',
  'from-green-400',
  'to-green-500'
);

export const gameLogo = classnames('w-20');

const smallView = classnames('flex', 'flex-col', 'space-y-2', 'items-center');
const defaultView = classnames(
  'flex',
  'flex-col',
  'space-y-2',
  'items-start',
  'md:space-y-0',
  'md:flex-row',
  'md:justify-between',
  'md:items-center',
  'px-4'
);
export const buttonTextWrapper = (isSmall: boolean): string =>
  classnames({ [defaultView]: !isSmall, [smallView]: isSmall });

const smallSpacing = classnames('pt-3', 'md:pb-0', 'text-center', 'space-y-2');
const defaultSpacing = classnames('text-center');

export const fpsWrapper = (isSmall: boolean): string =>
  classnames({
    [defaultSpacing]: !isSmall,
    [smallSpacing]: isSmall,
  });

const textWhite = classnames('text-white');
const textGrey50 = classnames('text-nzxt-dark-grey-50');
const tetxGrey500 = classnames('text-nzxt-dark-grey-500');

export const fpsHeading = (isInverted: boolean): string =>
  classnames('uppercase', 'font-bold', {
    [textWhite]: isInverted,
    [tetxGrey500]: !isInverted,
  });
export const fpsText = classnames('ml-1', 'text-nzxt-light-grey-800');
export const gameFps = classnames('text-nzxt-green-400', 'font-bold');
export const estimated = (isInverted: boolean): string =>
  classnames('text-xxs', 'italic', {
    [textGrey50]: isInverted,
    [tetxGrey500]: !isInverted,
  });
