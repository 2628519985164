/* eslint-disable max-len */
import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import type { ReviewsListItem } from '@framework/api/types';
import Stars from './Stars';
import * as styles from './styles';

type Props = {
  review: ReviewsListItem;
};

const ReviewsItem: FC<Props> = ({ review }) => {
  const { locale } = useRouter();

  const formattedDate = new Date(review.date).toLocaleDateString(locale, {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

  return (
    <li className={styles.listItem}>
      <div className={styles.innerWrapper}>
        <div className={styles.headingWrapper}>
          <p className={styles.reviewHeading}>
            <span className={styles.bold}>{review.username} |</span>
            <span className={styles.bold}>{review.state} |</span>
            <time dateTime={review.date} className={styles.italic}>
              {formattedDate}
            </time>
          </p>
          <Stars rating={review.rating} />
        </div>
        <div className={styles.descriptionWrapper}>
          <p className={styles.description}>&quot;{review.description}&quot;</p>
        </div>
        {review.isCustomPc && (
          <div className={styles.buildInfo}>
            <div className={styles.buildInfoInner}>
              <p className={styles.buildDetails}>{review.gpu}</p>
              <p className={styles.buildDetails}>{review.cpu}</p>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export default memo(ReviewsItem);
