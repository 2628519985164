import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import { getRegion } from '@framework/api/utils/maxify';
import { MAXIFY_REGION_EUROPE, MAXIFY_REGION_UNITED_KINGDOM } from '@constants';
import * as styles from './styles';

type Props = {
  eyebrow?: string;
  heading: string;
  subheading?: string;
};

const HeadingSection: FC<Props> = ({ heading, subheading, eyebrow }) => {
  const { locale } = useRouter();
  const region = getRegion(locale);

  // TO DO: REMOVE CHECK FOR EUROPE WHEN SECTION IS REGIONALLY AVAILABLE
  return region !== MAXIFY_REGION_EUROPE &&
    region !== MAXIFY_REGION_UNITED_KINGDOM ? (
    // eslint-disable-next-line
    <div className={styles.wrapper}>
      <p>{eyebrow}</p>
      <h2 className={styles.heading}>{heading}</h2>
      <p className={styles.subheading}>{subheading}</p>
    </div>
  ) : null;
};

export default memo(HeadingSection);
