import { FC, useRef, useState, useEffect, memo } from 'react';
import { IconChevronLeft, IconChevronRight } from '@nzxt/react-icons';
import cx from 'clsx';
import EmbedItem from './EmbedItem';
import * as styles from './styles';

interface EmbedScrollerProps {
  urlArray: string[];
}

const EmbedScroller: FC<EmbedScrollerProps> = ({ urlArray }) => {
  const embedScrollerRef = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const scrollTo = (offset: number): void => {
    if (embedScrollerRef.current) {
      const { clientWidth, scrollWidth, scrollLeft } = embedScrollerRef.current;
      const embedItemSize =
        embedScrollerRef.current.querySelector('div')?.clientWidth || 0;
      const newScrollLeft = scrollLeft + embedItemSize * offset;
      const boundedScrollLeft = Math.min(
        Math.max(newScrollLeft, 0),
        scrollWidth - clientWidth
      );
      embedScrollerRef.current.scroll({
        top: 0,
        left: boundedScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const scroller = embedScrollerRef?.current ?? null;
    const checkScrollPosition = (): void => {
      if (scroller) {
        const { scrollLeft, clientWidth, scrollWidth } = scroller;
        setIsAtStart(scrollLeft === 0);
        const threshold = 5;
        setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - threshold);
      }
    };

    if (scroller) {
      scroller.addEventListener('scroll', checkScrollPosition);
    }

    return (): void => {
      if (scroller) {
        scroller.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, []);

  return (
    <div className="relative flex justify-center items-center">
      <button
        type="button"
        onClick={() => scrollTo(-1)}
        aria-label="Previous slide"
        className={cx(styles.button, 'mr-4', {
          'opacity-0 transform scale-50': isAtStart,
          'opacity-100 transform scale-100': !isAtStart,
        })}
      >
        <IconChevronLeft className="text-4xl" />
      </button>
      <div ref={embedScrollerRef} className={styles.scrollerWrapper}>
        {urlArray.map(url => (
          <div key={url} className={styles.embedItemWrapper}>
            <EmbedItem url={url} />
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={() => scrollTo(1)}
        aria-label="Next slide"
        className={cx(styles.button, 'ml-4', {
          'opacity-0 transform scale-50': isAtEnd,
          'opacity-100 transform scale-100': !isAtEnd,
        })}
      >
        <IconChevronRight className="text-4xl" />
      </button>
    </div>
  );
};

export default memo(EmbedScroller);
