/* eslint-disable react/jsx-no-useless-fragment */
import { FC, memo } from 'react';
import { ComparisonProduct, DatoProduct } from '@framework/api/types';
import { SM_NAV_HEIGHT } from '@constants';

import Price from '../Price';
import * as styles from './styles';

type Props = {
  comparisonProducts: DatoProduct[] | ComparisonProduct[];
  currencyCode: string;
  selectedItem: number;
  setSelectedItem: (index: number) => void;
  region?: string;
};

const PrebuiltComparisonTableSm: FC<Props> = ({
  currencyCode,
  comparisonProducts,
  selectedItem,
  setSelectedItem,
  region,
}) =>
  comparisonProducts &&
  Array.isArray(comparisonProducts) &&
  comparisonProducts?.length > 0 ? (
    <>
      {comparisonProducts?.map((item, i) => {
        const isRegionallyAvailable = Array.isArray(item?.availableRegions)
          ? item?.availableRegions.some(r => r.region === region)
          : false;

        return item ? (
          <div
            key={item?.name}
            className={styles.buttonWrapper(i === selectedItem)}
            style={{ top: SM_NAV_HEIGHT, zIndex: 1 }}
          >
            <button
              type="button"
              onClick={() => setSelectedItem(i)}
              className={styles.buttonSelects}
            >
              <span className={styles.heading}>{item?.name}</span>
              {isRegionallyAvailable && (
                <Price
                  slug={item?.slug}
                  priceBase={item?.variants[0].price}
                  priceDiscount={item?.variants[0].discounted_price}
                  isOnSale={item?.variants[0].isOnSale}
                  currencyCode={currencyCode}
                />
              )}
            </button>
          </div>
        ) : null;
      })}
    </>
  ) : null;

export default memo(PrebuiltComparisonTableSm);
