import { classnames } from '@tailwindcss-classnames';

export const heading = classnames('font-bold', 'md:text-lg');
export const price = classnames('mx-auto');

export const gridWrapper = classnames('grid');
export const colSpan = {
  1: classnames('grid-cols-1'),
  2: classnames('grid-cols-2'),
  3: classnames('grid-cols-3'),
  4: classnames('grid-cols-4'),
};

const showCell = classnames('opacity-100');
const hideCell = classnames('hidden', 'opacity-0');
export const dataCell = (showMore: boolean): string =>
  classnames(
    'flex',
    'flex-col',
    'md:flex-row',
    'items-center',
    'md:justify-center',
    'py-4',
    'px-2',
    'md:px-4',
    'border-b',
    {
      [showCell]: showMore,
      [hideCell]: !showMore,
    }
  );

export const dataLabel = classnames('font-bold');
export const data = classnames(
  'w-full',
  'mx-auto',
  'md:ml-auto',
  'md:mr-0',
  'flex',
  'text-center',
  'md:text-right',
  'justify-center',
  'md:justify-end',
  'md:w-3/4',
  'items-center'
);
const isInactiveButton = classnames('border-b');
const isActiveButton = classnames('border-b-4', 'border-nzxt-volt-500');
export const buttonWrapper = (isActive: boolean): string =>
  classnames(
    'col-span-1',
    'order-1',
    'py-4',
    'mx-auto',
    'sticky',
    'bg-white',
    'w-full',
    {
      [isActiveButton]: isActive,
      [isInactiveButton]: !isActive,
    }
  );
export const buttonSelects = classnames('px-2', 'bg-white', 'w-full');
export const cardData = classnames('col-span-3', 'order-2', 'border-t');
export const fpsCellWrapper = classnames(
  'py-4',
  'border-b',
  'w-full',
  'flex',
  'justify-center',
  'items-center'
);
export const fpsCell = classnames('mx-auto');

const hasFeature = classnames('text-nzxt-volt-500');
const deafultFeature = classnames('text-nzxt-light-grey-400');
export const iconStyles = (isActive: boolean): string =>
  classnames('fill-current', 'w-6', 'h-6', 'mx-auto', {
    [hasFeature]: isActive,
    [deafultFeature]: !isActive,
  });

export const featureCell = classnames(
  'text-sm',
  'col-span-4',
  'flex',
  'items-center',
  'py-4',
  'border-b',
  'w-full'
);
