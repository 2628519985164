/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import cn from 'clsx';
import HydrateMdx from '@components/HydrateMdx';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import extraStyles from '../extraStyles.module.css';
import * as styles from './styles';

type Props = {
  label?: string;
  markdown: MDXRemoteSerializeResult;
  baseContent: string;
  isFirstCol?: boolean;
  minHeight?: number;
  showMore?: boolean;
};

const DataCell: FC<Props> = ({
  label,
  markdown,
  baseContent,
  isFirstCol,
  minHeight,
  showMore,
}) =>
  markdown || baseContent ? (
    <div
      className={styles.dataCell(isFirstCol, showMore)}
      style={{ minHeight: `${minHeight > 0 && `${minHeight}px`}` }}
      data-test-id="data-cell"
    >
      {isFirstCol && (
        <span className={styles.dataLabel} data-test-id="data-cell-category">
          {label}
        </span>
      )}
      <span
        className={cn(styles.cellData(isFirstCol), extraStyles.markdown)}
        data-test-id="data-cell-content"
      >
        {markdown ? <HydrateMdx mdx={markdown} /> : baseContent}
      </span>
    </div>
  ) : null;

export default memo(DataCell);
