import { FC, memo, useState, Fragment, useMemo } from 'react';
import cn from 'clsx';
import type {
  PrebuiltCardsProps,
  PrebuildComparisonField,
  ComparisonProduct,
  DatoProduct,
} from '@framework/api/types';
import { useTranslation as t } from '@utils/hooks';
import FpsSection from '@components/FpsSection';
import SmallDataCell from './SmallDataCell';
import FeaturesCell from '../FeaturesCell';
import TopSection from './TopSection';
import * as styles from './styles';

type Props = {
  prebuiltCards: PrebuiltCardsProps;
  comparisonProducts: DatoProduct[] | ComparisonProduct[];
  currencyCode: string;
  showMore: boolean;
  enabledCardFields: PrebuildComparisonField[];
  chipsetType?: string;
  region?: string;
};

const PrebuiltComparisonTableSm: FC<Props> = ({
  prebuiltCards,
  showMore,
  currencyCode,
  comparisonProducts,
  enabledCardFields,
  chipsetType,
  region,
}) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const CASE = t('label_case');
  const SOFTWARE = t('label_software');
  const RAM = t('label_ram');
  const CPU = t('label_cpu');
  const GPU = t('label_gpu');
  const MOTHERBOARD = t('label_motherboard');
  const STORAGE = t('label_storage');
  const COOLING = t('label_cooling');
  const PSU = t('label_psu');
  const WARRANTY = t('label_warranty');
  const ACCESSORIES = t('label_accessories');
  const FEATURE = t('label_feature');

  const productLength = Array.isArray(prebuiltCards)
    ? prebuiltCards?.length
    : 0;
  const composedColumnClassName = useMemo(
    () =>
      cn(
        styles.gridWrapper,
        styles.colSpan[(productLength <= 4 && productLength) || 3]
      ),
    [productLength]
  );

  return prebuiltCards &&
    Array.isArray(prebuiltCards) &&
    prebuiltCards?.length > 0 ? (
    <div className={composedColumnClassName}>
      <TopSection
        comparisonProducts={comparisonProducts}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        currencyCode={currencyCode}
        region={region}
      />

      {prebuiltCards?.map((card, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${card.gpu}-${i}`}>
          {selectedItem === i && (
            <div className={styles.cardData}>
              <div className={styles.fpsCellWrapper}>
                <div className={styles.fpsCell}>
                  <FpsSection
                    fps={card.productFps}
                    chipsetType={chipsetType}
                    isDefaultButton
                    isSmall
                  />
                </div>
              </div>
              {enabledCardFields.some(f => f.field === 'cpu') && (
                <SmallDataCell
                  label={CPU}
                  markdown={card.cpuMd}
                  baseContent={card.cpu}
                  showMore
                />
              )}
              {enabledCardFields.some(f => f.field === 'gpu') && (
                <SmallDataCell
                  label={GPU}
                  markdown={card.gpuMd}
                  baseContent={card.gpu}
                  showMore
                />
              )}
              {enabledCardFields.some(f => f.field === 'ram') && (
                <SmallDataCell
                  label={RAM}
                  markdown={card.ramMd}
                  baseContent={card.ram}
                  showMore
                />
              )}
              {enabledCardFields.some(f => f.field === 'storage') && (
                <SmallDataCell
                  label={STORAGE}
                  markdown={card.storageMd}
                  baseContent={card.storage}
                  showMore
                />
              )}
              {enabledCardFields.some(f => f.field === 'case') && (
                <SmallDataCell
                  label={CASE}
                  markdown={card.caseMd}
                  baseContent={card.case}
                  showMore={showMore}
                />
              )}
              {enabledCardFields.some(f => f.field === 'motherboard') && (
                <SmallDataCell
                  label={MOTHERBOARD}
                  markdown={card.motherboardMd}
                  baseContent={card.motherboard}
                  showMore={showMore}
                />
              )}
              {enabledCardFields.some(f => f.field === 'cpuCooler') && (
                <SmallDataCell
                  label={COOLING}
                  markdown={card.cpuCoolerMd}
                  baseContent={card.cpuCooler}
                  showMore={showMore}
                />
              )}
              {enabledCardFields.some(f => f.field === 'power') && (
                <SmallDataCell
                  label={PSU}
                  markdown={card.powerMd}
                  baseContent={card.power}
                  showMore={showMore}
                />
              )}
              {enabledCardFields.some(f => f.field === 'accessories') && (
                <SmallDataCell
                  label={ACCESSORIES}
                  markdown={card.accessoriesMd}
                  baseContent={card.accessories}
                  showMore={showMore}
                />
              )}
              {enabledCardFields.some(f => f.field === 'software') && (
                <SmallDataCell
                  label={SOFTWARE}
                  markdown={card.softwareMd}
                  baseContent={card.software}
                  showMore={showMore}
                />
              )}
              {enabledCardFields.some(f => f.field === 'warranty') && (
                <SmallDataCell
                  label={WARRANTY}
                  markdown={card.warrantyMd}
                  baseContent={card.warranty}
                  showMore={showMore}
                />
              )}
              <FeaturesCell
                label={FEATURE}
                isFirstCol={i === 0}
                hasBluetooth={card.bluetooth}
                hasRgb={card.rgb}
                hasWifi={card.wifi}
                hasUsbc={card.usbc}
                isStreamReady={card.streamReady}
                isVrReady={card.vrReady}
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  ) : null;
};

export default memo(PrebuiltComparisonTableSm);
