/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useMemo } from 'react';
import cn from 'clsx';
import { useTranslation as t } from '@utils/hooks';
import {
  IconInternalusbHub,
  IconVr,
  IconWifi,
  IconLighting,
  IconBluetooth,
  IconStreaming,
} from '@nzxt/react-icons';

import * as styles from './PrebuiltComparisonTableLg/styles';

type Props = {
  label?: string;
  isFirstCol?: boolean;
  minHeight?: number;
  hasBluetooth?: boolean;
  hasRgb?: boolean;
  hasWifi?: boolean;
  hasUsbc?: boolean;
  isStreamReady?: boolean;
  isVrReady?: boolean;
  productLength?: number;
};

const FeaturesCell: FC<Props> = ({
  label,
  isFirstCol = false,
  minHeight,
  hasBluetooth = false,
  hasRgb = false,
  hasWifi = false,
  hasUsbc = false,
  isStreamReady = false,
  isVrReady = false,
  productLength,
}) => {
  const composedColumnClassName = useMemo(
    () =>
      cn(
        styles.featureCell(isFirstCol),
        styles.colSpan[(productLength < 4 && productLength) || 3]
      ),
    [productLength, isFirstCol]
  );

  // Streaming
  const STREAMING_READY_LABEL = t('streaming_ready_label');
  const STREAMING_NOT_READY_LABEL = t('streaming_not_ready_label');
  // VR
  const VR_READY_LABEL = t('vr_ready_label');
  const VR_NOT_READY_LABEL = t('vr_not_ready_label');
  // WiFi
  const WIFI_LABEL = t('has_wifi_label');
  const NO_WIFI_LABEL = t('no_wifi_label');
  // Bluetooth
  const BT_LABEL = t('has_bluetooth_label');
  const NO_BT_LABEL = t('no_bluetooth_label');
  // Lighting
  const RGB_LABEL = t('rgb_included_label');
  const NO_RGB_LABEL = t('rgb_not_included_label');
  // USBC
  const USBC_LABEL = t('has_usbc_label');
  const NO_USBC_LABEL = t('no_usbc_label');

  return (
    <div
      className={composedColumnClassName}
      style={{
        minHeight: `${minHeight && minHeight > 0 ? `${minHeight}px` : '113px'}`,
      }}
      data-test-id="feature-cell"
    >
      {isFirstCol && <span className={styles.dataLabel}>{label}</span>}

      <IconStreaming
        title={
          isStreamReady ? STREAMING_READY_LABEL : STREAMING_NOT_READY_LABEL
        }
        className={styles.iconStyles(isStreamReady)}
      />
      <IconVr
        title={isVrReady ? VR_READY_LABEL : VR_NOT_READY_LABEL}
        className={styles.iconStyles(isVrReady)}
      />
      <IconWifi
        title={hasWifi ? WIFI_LABEL : NO_WIFI_LABEL}
        className={styles.iconStyles(hasWifi)}
      />
      <IconLighting
        title={hasRgb ? RGB_LABEL : NO_RGB_LABEL}
        className={styles.iconStyles(hasRgb)}
      />
      <IconBluetooth
        title={hasBluetooth ? BT_LABEL : NO_BT_LABEL}
        className={styles.iconStyles(hasBluetooth)}
      />
      <IconInternalusbHub
        title={hasUsbc ? USBC_LABEL : NO_USBC_LABEL}
        className={styles.iconStyles(hasUsbc)}
      />
    </div>
  );
};

export default memo(FeaturesCell);
