import { classnames } from '@tailwindcss-classnames';

const darkFont = classnames('text-white');
const lightFont = classnames('text-nzxt-light-grey-800');
const defaultHeadingSize = classnames(
  'lg:text-4xl',
  'md:text-2xl',
  'text-base'
);
const smallHeadingSize = classnames('text-base');
export const heading = (isSmall: boolean, isInverted: boolean): string =>
  classnames('font-bold', {
    [defaultHeadingSize]: !isSmall,
    [smallHeadingSize]: isSmall,
    [darkFont]: isInverted,
    [lightFont]: !isInverted,
  });

const defaultSubheadingSize = classnames(
  'text-sm',
  'md:text-lg',
  'pb-2',
  'md:pb-4'
);
const smallSubheadingSize = classnames('text-sm', 'pb-4');
export const subheading = (isSmall: boolean, isInverted: boolean): string =>
  classnames({
    [defaultSubheadingSize]: !isSmall,
    [smallSubheadingSize]: isSmall,
    [darkFont]: isInverted,
    [lightFont]: !isInverted,
  });

const defaultSpacing = classnames('md:pl-12', 'lg:pl-20', 'w-1/2', 'md:w-full');
const smolSpacing = classnames('2xl:w-1/2', 'w-3/4');
export const textWrapper = (isSmall: boolean): string =>
  classnames('relative', 'my-auto', 'space-y-1', 'md:space-y-2', {
    [defaultSpacing]: !isSmall,
    [smolSpacing]: isSmall,
  });

const defaultImageSize = classnames('lg:max-w-md', 'xl:max-w-lg');
const smallImageSize = classnames('max-w-md');
export const image = (isSmall: boolean): string =>
  classnames('mx-auto', {
    [defaultImageSize]: !isSmall,
    [smallImageSize]: isSmall,
  });

const defaultSpace = classnames('py-6', 'px-3', 'md:p-20', 'rounded-lg');
const smallSpace = classnames(
  'w-full',
  'md:py-12',
  'py-6',
  'px-3',
  'md:px-6',
  'rounded-lg'
);

export const wrapper = (isSmall: boolean): string =>
  classnames('flex', 'overflow-hidden', 'bg-contain', {
    [defaultSpace]: !isSmall,
    [smallSpace]: isSmall,
  });

const darkBg = classnames(
  'bg-gradient-to-b',
  'from-nzxt-dark-grey-600',
  'to-nzxt-dark-grey-800',
  'overflow-hidden'
);
const lightBg = classnames('bg-nzxt-light-grey-100', 'overflow-hidden');

export const backgroundStyles = {
  light: lightBg,
  dark: darkBg,
};
export const extendedButton = classnames('max-w-max');
