import { classnames } from '@tailwindcss-classnames';

export const container = classnames('pt-16', 'pb-2', 'm-auto');

export const heading = classnames('h4');

export const reviewsWrapper = classnames(
  'mt-6',
  'overflow-hidden',
  'sm:rounded-md',
  'rounded-sm'
);

export const title = classnames(
  'h1',
  'text-center',
  'uppercase',
  'text-gradient',
  'bg-gradient-to-t',
  'to-nzxt-light-grey-600',
  'from-nzxt-light-grey-800'
);
export const reviewsList = classnames('grid', 'gap-4', 'lg:grid-cols-2');

export const topWrapper = classnames('flex', 'mt-2', 'items-start');
export const starsWrapper = classnames('flex', 'items-center');
export const star = classnames('text-nzxt-volt-400', 'w-5', 'h-5');
export const reviewText = classnames(
  'ml-3',
  'text-nzxt-light-grey-800',
  'text-base'
);

// ReviewsItem
export const listItem = classnames(
  'bg-nzxt-light-grey-50',
  'shadow',
  'rounded-sm'
);
export const innerWrapper = classnames(
  'flex',
  'flex-col',
  'px-4',
  'py-4',
  'sm:px-6',
  'h-full'
);

export const headingWrapper = classnames(
  'flex',
  'flex-col',
  'items-start',
  'justify-between'
);
export const reviewHeading = classnames(
  'text-sm',
  'space-x-2',
  'text-gray-800'
);
export const bold = classnames('font-bold');
export const italic = classnames('italic');

export const descriptionWrapper = classnames(
  'mb-3',
  'flex',
  'items-center',
  'justify-between',
  'mt-3'
);
export const description = classnames(
  'text-sm',
  'font-medium',
  'text-nzxt-light-grey-700',
  'leading-7'
);
export const buildInfo = classnames(
  'mt-4',
  'sm:flex',
  'mt-3',
  'bg-gray-100',
  'p-2',
  'mt-auto',
  'border-t',
  'border-gray-300'
);
export const buildInfoInner = classnames(
  'sm:flex',
  'flex-col',
  'sm:items-start',
  'space-y-2'
);
export const buildDetails = classnames('text-xs', 'flex', 'text-gray-500');
export const buttonWrapper = classnames(
  'mt-4',
  'mb-1',
  'sm:mb-0',
  'sm:mt-0',
  'flex',
  'ml-auto',
  'text-sm',
  'text-gray-500',
  'sm:mt-0',
  'rounded-sm',
  'justify-center',
  'items-center'
);
