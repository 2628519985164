import { classnames } from '@tailwindcss-classnames';

export const gridWrapper = classnames('grid', 'grid-cols-12', 'divide-x');

export const colSpan = {
  1: classnames('col-span-12'),
  2: classnames('col-span-6'),
  3: classnames('col-span-4'),
  4: classnames('col-span-3'),
};

export const column = classnames(
  'border-r',
  'last:border-0',
  'flex',
  'flex-col',
  'items-center'
);

export const productHeadingWrapper = classnames(
  'w-full',
  'p-8',
  'border-b',
  'w-full',
  'bg-white',
  'sticky',
  'items-center',
  'border-l',
  'first:border-l-0',
  'text-center'
);
export const productHeading = classnames('text-xl', 'font-bold');
export const productPrice = classnames();

const cellWithLabel = classnames('pr-2', 'w-full');
const defaultCell = classnames('px-2');
const showCell = classnames('opacity-100');
const hideCell = classnames('hidden', 'opacity-0');

export const dataCell = (hasLabel: boolean, showMore: boolean): string =>
  classnames(
    'text-sm',
    'flex',
    'items-center',
    'space-x-12',
    'py-4',
    'border-b',
    'w-full',
    'transition-all',
    'duration-100',
    {
      [cellWithLabel]: hasLabel,
      [defaultCell]: !hasLabel,
      [showCell]: showMore,
      [hideCell]: !showMore,
    }
  );

export const fpsCellWrapper = classnames(
  'py-4',
  'border-b',
  'w-full',
  'flex',
  'justify-center',
  'items-center'
);
export const fpsCell = classnames();

const dataWithLabel = classnames('w-3/4');
const defaultData = classnames('justify-center', 'px-4');

export const cellData = (hasLabel: boolean): string =>
  classnames(
    {
      [dataWithLabel]: hasLabel,
      [defaultData]: !hasLabel,
    },
    'flex',
    'items-center'
  );

export const dataLabel = classnames(
  'hidden',
  'lg:block',
  'w-1/4',
  'items-start',
  'font-bold'
);

const hasFeature = classnames('text-nzxt-volt-500');
const deafultFeature = classnames('text-nzxt-light-grey-400');
export const iconStyles = (isActive: boolean): string =>
  classnames('fill-current', 'w-6', 'h-6', 'mx-auto', {
    [hasFeature]: isActive,
    [deafultFeature]: !isActive,
  });

export const featureCell = (hasLabel: boolean): string =>
  classnames('text-sm', 'flex', 'items-center', 'py-4', 'border-b', 'w-full', {
    [cellWithLabel]: hasLabel,
    [defaultCell]: !hasLabel,
  });
