/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
/* eslint-disable react/no-danger */
import { FC, memo } from 'react';
import EmbedItem from './EmbedItem';
import EmbedScroller from './EmbedScroller';

type Props = {
  url: string;
};

const Embed: FC<Props> = ({ url, ...rest }) => {
  if (!url) {
    return null;
  }

  const urlArray = url.split(',').map(u => u.trim());

  if (urlArray.length === 1) {
    return <EmbedItem url={urlArray[0]} centered {...rest} />;
  }

  return (
    <>
      <EmbedScroller urlArray={urlArray} />
    </>
  );
};

export default memo(Embed);
