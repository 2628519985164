/* eslint-disable max-len */
import { FC, memo } from 'react';
import * as styles from './styles';

type Props = {
  rating: string;
};

const Stars: FC<Props> = ({ rating }) => {
  const fullStar = (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.starIcon}
    >
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
    </svg>
  );

  const halfStar = (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.starIcon}
    >
      <path
        fillRule="evenodd"
        d="M5.354 5.119L7.538.792A.516.516 0 018 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0116 6.32a.55.55 0 01-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.519.519 0 01-.146.05c-.341.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 01-.171-.403.59.59 0 01.084-.302.513.513 0 01.37-.245l4.898-.696zM8 12.027c.08 0 .16.018.232.056l3.686 1.894-.694-3.957a.564.564 0 01.163-.505l2.906-2.77-4.052-.576a.525.525 0 01-.393-.288L8.002 2.223 8 2.226v9.8z"
        clipRule="evenodd"
      />
    </svg>
  );

  const emptyStar = (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.starIcon}
    >
      <path
        fillRule="evenodd"
        d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.523-3.356c.329-.314.158-.888-.283-.95l-4.898-.696L8.465.792a.513.513 0 00-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767l-3.686 1.894.694-3.957a.565.565 0 00-.163-.505L1.71 6.745l4.052-.576a.525.525 0 00.393-.288l1.847-3.658 1.846 3.658a.525.525 0 00.393.288l4.052.575-2.906 2.77a.564.564 0 00-.163.506l.694 3.957-3.686-1.894a.503.503 0 00-.461 0z"
        clipRule="evenodd"
      />
    </svg>
  );

  const FIVE = [
    { id: '1', icon: fullStar },
    { id: '2', icon: fullStar },
    { id: '3', icon: fullStar },
    { id: '4', icon: fullStar },
    { id: '5', icon: fullStar },
  ];
  const FOUR_POINT_FIVE = [
    { id: '1', icon: fullStar },
    { id: '2', icon: fullStar },
    { id: '3', icon: fullStar },
    { id: '4', icon: fullStar },
    { id: '5', icon: halfStar },
  ];
  const FOUR = [
    { id: '1', icon: fullStar },
    { id: '2', icon: fullStar },
    { id: '3', icon: fullStar },
    { id: '4', icon: fullStar },
    { id: '5', icon: emptyStar },
  ];
  const THREE_POINT_FIVE = [
    { id: '1', icon: fullStar },
    { id: '2', icon: fullStar },
    { id: '3', icon: fullStar },
    { id: '4', icon: halfStar },
    { id: '5', icon: emptyStar },
  ];
  const THREE = [
    { id: '1', icon: fullStar },
    { id: '2', icon: fullStar },
    { id: '3', icon: fullStar },
    { id: '4', icon: emptyStar },
    { id: '5', icon: emptyStar },
  ];
  const TWO_POINT_FIVE = [
    { id: '1', icon: fullStar },
    { id: '2', icon: fullStar },
    { id: '3', icon: halfStar },
    { id: '4', icon: emptyStar },
    { id: '5', icon: emptyStar },
  ];
  const TWO = [
    { id: '1', icon: fullStar },
    { id: '2', icon: fullStar },
    { id: '3', icon: emptyStar },
    { id: '4', icon: emptyStar },
    { id: '5', icon: emptyStar },
  ];
  const ONE_POINT_FIVE = [
    { id: '1', icon: fullStar },
    { id: '2', icon: halfStar },
    { id: '3', icon: emptyStar },
    { id: '4', icon: emptyStar },
    { id: '5', icon: emptyStar },
  ];
  const ONE = [
    { id: '1', icon: fullStar },
    { id: '2', icon: emptyStar },
    { id: '3', icon: emptyStar },
    { id: '4', icon: emptyStar },
    { id: '5', icon: emptyStar },
  ];
  const POINT_FIVE = [
    { id: '1', icon: halfStar },
    { id: '2', icon: emptyStar },
    { id: '3', icon: emptyStar },
    { id: '4', icon: emptyStar },
    { id: '5', icon: emptyStar },
  ];

  const STAR_MAP = {
    '5': FIVE,
    '5.0': FIVE,
    '4.5': FOUR_POINT_FIVE,
    '4': FOUR,
    '4.0': FOUR,
    '3.5': THREE_POINT_FIVE,
    '3': THREE,
    '3.0': THREE,
    '2.5': TWO_POINT_FIVE,
    '2': TWO,
    '2.0': TWO,
    '1.5': ONE_POINT_FIVE,
    '1': ONE,
    '1.0': ONE,
    '.5': POINT_FIVE,
    '0.5': POINT_FIVE,
  };

  return (
    <ul className={styles.stars}>
      {STAR_MAP[rating].map(i => (
        <li key={i.id}>{i.icon}</li>
      ))}
    </ul>
  );
};

export default memo(Stars);
